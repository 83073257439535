import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [
  {
    path:'/',
    children:[
      {
        path:'',
        redirect:'/index'
      }
    ]
  },
  {
    path:'/login',
    name:'Login',
    component:() => import('@/views/login/loginPage.vue')
  },
  // {
  //   path:'/404',
  //   name:'404',
  //   component:() => import('@/views/404NotFound.vue')
  // },
  // {
  //   path:'/register',
  //   name:'Register',
  //   component:() => import('@/views/login/registerPage.vue')
  // },
  {
    path:'/forget',
    name:'Forget',
    component:() => import('@/views/login/forgetPage.vue')
  },
  {
    path:'/edit',
    name:'Edit',
    component:() => import('@/views/login/editPage.vue')
  },
  {
    path:'/index',
    name:'Index',
    component:() => import('@/views/Index/IndexPage.vue')
  },
  {
    path:'/userInfo',
    name:'UserInfo',
    component:() => import('@/views/userInfo/IndexPage.vue')
  },
  {
    path:'/courseList',
    name:'CourseList',
    component:() => import('@/views/course/courseList.vue')
  },
  {
    path:'/courseTree',
    name:'CourseTree',
    component:() => import('@/views/course/courseTree.vue')
  },
  {
    path:'/courseVideo/:courseId',
    name:'CourseVideo',
    component:() => import('@/views/course/courseVideo.vue')
  },
  {
    path:'/payPage/:id',
    name:'PayPage',
    component:() => import('@/views/pay/payPage.vue')
  }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router